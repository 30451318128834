<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <unmatched-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" :listOfDeclineReason="listOfReasons" v-on:decline-transaction="declineTransactions(details.item)"/>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Unmatched Transactions</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-1">
            <v-col cols="12" md="5">
                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="5">
                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                        <v-btn text color="primary" @click="clearToDate()">Clear
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col class="d-flex" cols="12" md="2">
                <v-autocomplete
                    clearable chips
                    :items="merchantCodes" 
                    label="Merchant ID" 
                    data-cy="module-filtered-transaction-merchant-code"
                    v-model="filteredTransaction.merchantCode" 
                    return-object
                    @change="searchMerchantCode">
                </v-autocomplete>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Payconnect Reference" single-line @change="searchPayConnectRef" data-cy="module-payconnect-reference"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Merchant Reference" single-line @change="searchMerchantRef" data-cy="module-merchant-reference"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-card-actions elevation="2" class="container--fluid grid-list-md text-center">
                    <v-btn :disabled='isDisabled' class="ml-5" color=success @click="queryData(true)" data-cy="action-button-search">
                        <v-icon dark left> mdi-magnify</v-icon>Search
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex mt-4" cols="12" md="1">
                <v-flex xs12>
                    <v-btn :disabled='isCreateDisabled' class="ml-5" color=success @click="download()" data-cy="action-button-export">Export</v-btn>
                </v-flex>
            </v-col>
        </v-row>
        <v-row >
            <v-col cols="12" md="6">    
                <strong class="mr-2">Total Count: </strong>   
                <span class="price-content">{{commarized(totalCount)}}</span>
            </v-col> 
            <v-col cols="12" md="4">    
                 <strong class="mr-2">Total Amount: </strong>
                <span class="price-content">{{commarized(amountTotal)}}</span>
            </v-col>
        </v-row>    
    </v-card>

    <!-- <div class="text-uppercase text-bold">id selected: {{selected}}</div> -->
    <!-- <div class="col-md-6 text-right">
        <strong>Total Amount:</strong> <span class="price-content">{{totalAmount}}</span>
    </div> -->

    <template>
        <v-card>
            <v-tabs marginTop="100" v-model="tab" background-color="transparent" color="blue" >
                    <v-tab>
                        MERCHANT
                    </v-tab>
                    <v-tab>
                        PAYCONNECT
                    </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-data-table :hide-default-header="true" :headers="merchantHeaders" :items="displayMerchantTransactions" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMerchantTransactions" :footer-props="{'items-per-page-options': [10]}">
                        <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in merchantHeaders" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="items.length > 0">
                                    <tr :colspan="merchantHeaders.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.deviceId }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.siteId }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.payconnectReference }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.amount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.merchantReference }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.transactionDate }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.transactionReferenceNo }} </span></td>
                                        <td> <span class="d-flex justify-center red--text"> {{ item.paymentStatus }} </span></td>
                                        <td>
                                            <span class="d-flex justify-center">
                                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewMerchantTransaction(item)">
                                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                </v-btn>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <td :colspan="merchantHeaders.length"> <span class="d-flex justify-center mt-3 mb-3"> No results found. </span></td>
                                </tbody>
                            </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-data-table :hide-default-header="true" :headers="headers" :items="displayPayconnectTransactions" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalPayconnectTransctions" :footer-props="{'items-per-page-options': [10]}">
                        <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in headers" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="items.length > 0">
                                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.payconnectReference }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.amount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.merchantReference }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.transactionDate }} </span></td>
                                        <td>
                                            <span class="d-flex justify-center">
                                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTransaction(item)">
                                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                </v-btn>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <td :colspan="headers.length"> <span class="d-flex justify-center mt-3 mb-3"> No results found. </span></td>
                                </tbody>
                            </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>        
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import UnmatchedForm from '@/views/components/UnmatchedForm.vue'
import moment from 'moment';

export default {
    name: 'Unmatched',
    components: {
        Loading,
        UnmatchedForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
        count() {
    	    return this.selected.length;
        },
        isDisabled() {
           if(!this.filteredTransaction.merchantCode) {
               return this.buttonDisabled = true
           } else {
               return this.buttonDisabled = false
           }
        },
        isCreateDisabled() {
            if(!(this.amountTotal && this.totalCount && this.filteredTransaction.merchantCode && this.toDateVal && this.loading === false)) {
               return this.buttonDisabled = true
           } else {
               return this.buttonDisabled = false
           }
        },
        totalAmount() {
            return this.selected.reduce(function (a,dailyReports) {
      	        return parseInt(a) + parseInt(dailyReports.amount);
        }, 0);
    }
    },
    data() {
        return {
            merchantHeaders: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Device ID',
                    value: 'deviceId'
                },
                {
                    text: 'Site ID',
                    value: 'siteId'
                },
                {
                    text: 'Merchant',
                    value: 'merchantCode'
                },
                {
                    text: 'Reference Number',
                    value: 'payconnectReference'
                },
                {
                    text: 'Amount',
                    value: 'amount'
                },
                {
                    text: 'Merchant Ref. Num.',
                    value: 'merchantReference'
                },
                {
                    text: 'Date',
                    value: 'transactionDate'
                },
                {
                    text: 'Transaction Ref. Num.',
                    value: 'transactionReferenceNo'
                },
                {
                    text: 'Status',
                    value: 'paymentStatus'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            headers: [
                {
                    text: 'Merchant',
                    value: 'merchantCode'
                },
                {
                    text: 'Reference Number',
                    value: 'payconnectReference'
                },
                {
                    text: 'Amount',
                    value: 'amount'
                },
                {
                    text: 'Merchant Ref. Num.',
                    value: 'merchantReference'
                },
                {
                    text: 'Date',
                    value: 'transactionDate'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            listOfMerchantCodes: [],
            merchantCodes: this.listOfMerchantCodes ?? [],
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMerchantTransactions: 0,
            totalPayconnectTransctions: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayMerchantTransactions: [],
            displayPayconnectTransactions: [],
            remainderMerchantTransactions: [],
            remainderPayconnectTransactions: [],
            listOfReasons: [],
            declineReasons: this.listOfReasons ?? [],
            totalRecords: 0,
            itemsPerPage: 10,
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            selected: [],
	    	selectAll: false,
            amountTotal: "0",
            totalCount: "0",
            tab: null,

        }
    },
    async mounted() {
        this.setDefaultDateRange()
        this.setData();
    },
    watch: {
        options: {
            handler() {
                this.loadingDialog = false;
                this.queryData(false);
                
            },
        },
        listOfMerchantCodes: {
            handler(value) {
                this.merchantCodes = value
            }
        },
        listOfReasons: {
            handler(value) {
                this.declineReasons = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getMerchantCode()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfMerchantCodes = listResponse.merchantCodes
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getDeclineReasons()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfReasons = listResponse.reasons
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            this.loadingDialog = true
            this.loadingMessage = `Searching, please wait...`
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var limit = itemsPerPage	
                if(limit < 0)	
                    limit = this.totalRecords	
                // var matchedTransactionList = undefined

                var merchantTransactionList = undefined
                var payconnectTransactionList = undefined
                const tabTransaction = this.tab === 0 ? 'MERCHANT' : 'PAYCONNECT'
                
                const parameters = {
                    merchantCode: this.filteredTransaction.merchantCode,
                    payconnectReference: this.filteredTransaction.payconnectReference,
                    merchantReference: this.filteredTransaction.merchantReference,
                    dateFrom: this.fromDateVal, 
                    dateTo: this.toDateVal,
                    pageNo: pageNumber,
                    pageSize: itemsPerPage,
                    pageSize: limit
                }

                if(tabTransaction === 'MERCHANT') {

                    //MERCHANT TAB TRANSACTIONS
                    merchantTransactionList = await API.getMerchantTransactions(parameters)
                    if (!merchantTransactionList || merchantTransactionList.error) {
                        console.log(`${merchantTransactionList.error}`)
                    } else {
                        var filteredList = merchantTransactionList.merchantTransactions
                        var length = merchantTransactionList.rowCount
                        this.amountTotal = merchantTransactionList.totalAmount ?? 0.00
                        this.totalCount = merchantTransactionList.rowCount
                        if (page > 1 && this.remainderMerchantTransactions.length > 0) {
                            filteredList = this.remainderMerchantTransactions.concat(filteredList)
                        }
                        // this.totalMerchantTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        this.totalMerchantTransactions = length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayMerchantTransactions = filteredList.slice(0, end)
                        this.remainderMerchantTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = this.totalMerchantTransactions / itemsPerPage

                        parameters.offset = this.totalMerchantTransactions
                        parameters.limit = 1
                        
                        const hasMoreResponse = merchantTransactionList
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.merchantTransactions
                            if(hasMoreList.length > 0) {
                            // this.totalMerchantTransactions =this.totalMerchantTransactions + 1
                            this.numberOfPages = this.numberOfPages + 1
                            }
                        }
                    this.page = page	
                    this.itemsPerPage = itemsPerPage
                    }
                    this.loading = false
                    this.loadingDialog = false
                }else {

                    //PAYCONNECT TAB TRANSCTIONS
                    payconnectTransactionList = await API.getPayconnectTransactions(parameters)
                    if (!payconnectTransactionList || payconnectTransactionList.error) {
                        console.log(`${payconnectTransactionList.error}`)
                    } else {
                        var filteredList = payconnectTransactionList.payconnectTransactions
                        var length = payconnectTransactionList.rowCount
                        this.amountTotal = payconnectTransactionList.totalAmount ?? 0.00
                        this.totalCount = payconnectTransactionList.rowCount
                        if (page > 1 && this.remainderPayconnectTransactions.length > 0) {
                            filteredList = this.remainderPayconnectTransactions.concat(filteredList)
                        }
                        // this.totalPayconnectTransctions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        this.totalPayconnectTransctions = length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayPayconnectTransactions = filteredList.slice(0, end)
                        this.remainderPayconnectTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = this.totalPayconnectTransctions / itemsPerPage

                        parameters.offset = this.totalPayconnectTransctions
                        parameters.limit = 1
                        
                        const hasMoreResponse = payconnectTransactionList
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.payconnectTransactions
                            if(hasMoreList.length > 0) {
                            // this.totalPayconnectTransctions = this.totalPayconnectTransctions.length + 1
                            this.numberOfPages = this.numberOfPages + 1
                            }
                        }
                    }
                                this.loading = false
                    this.loadingDialog = false
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
            this.loadingDialog = false
        },
        searchMerchantCode: async function (val) {
            this.filteredTransaction.merchantCode = val
            // this.queryData(true)
        },
        searchPayConnectRef(val) {
            this.filteredTransaction.payconnectReference = val
            // this.queryData(true)
        },
        searchMerchantRef(val) {
            this.filteredTransaction.merchantReference = val
            // this.queryData(true)
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.fromDateVal = this.getToday();
            this.toDateVal = this.getToday();
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.fromDateVal)
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async download() {
            const tabTransaction = this.tab === 0 ? 'MERCHANT' : 'PAYCONNECT'
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.loadingMessage = `Downloading CSV please wait...`
            var downloadResponse = null;
            if(tabTransaction === 'MERCHANT') {
                downloadResponse = await API.exportMerchantTransaction(this.filteredTransaction.merchantCode,
                    this.fromDateVal,this.toDateVal, this.filteredTransaction.payconnectReference, this.filteredTransaction.merchantReference)
            } else {
                downloadResponse = await API.exportPayconnectTransaction(this.filteredTransaction.merchantCode,
                    this.fromDateVal,this.toDateVal, this.filteredTransaction.payconnectReference, this.filteredTransaction.merchantReference)
            }
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        viewMerchantTransaction(transaction) {
            console.log(transaction)
            this.details.isUnmatched = true
            this.details.item = transaction
            this.isShowDetails = true
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.isUnmatched = false
            this.details.item = transaction
            this.isShowDetails = true
        },
		selectAllMerchantAtOnce() {
			this.selected = [];
			if (!this.selectAll) {
                 for (let index in this.displayMerchantTransactions) {
                      this.selected.push(this.displayMerchantTransactions[index]);
                }
                //for improvement
			} 
            console.log(this.selected);
		},
        selectAllPayconnectAtOnce() {
            this.selected = [];
            if (!this.selectAll) {
                for (let index in this.displayPayconnectTransactions) {
                    this.selected.push(this.displayPayconnectTransactions[index]);
                }
                //for improvement
            } 
            console.log(this.selected);
        },
        selectOne() {
            this.selectAll = false;
        },
        async declineTransactions(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            console.log("DECLINE NAME: " + value.name)
            if (value) {
                this.loadingMessage = `Declining unmatched merchant transaction: <strong><i>${value.id}</i></strong>.`
                setTimeout(async () => {
                    const createResponse = await API.updateUnmatchedToDecline(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully declined unmatched transaction: <strong><i>${value.id}</i>.`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        commarized(value) {
            var numeral = require("numeral");
            return numeral(value).format("0,0[.]00");
        }
    }
};
</script>
