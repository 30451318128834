<template>
<v-container row justify-center>
    <v-dialog max-width="600px" persistent v-model="isShowDetails">
        <v-card>
            <v-card-title class="headline">
                {{details.title}}
            </v-card-title>
            <v-card-text>
                <v-row v-for="(field, index) of getFields()" :key="index">
                    <v-col cols="12" v-if="isJSON(field[1])">
                        <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-flex xs12>
                            <v-autocomplete
                                v-if="isUnmatched()"
                                v-model="details.item.name" 
                                :items="reasonList"
                                label="Declined Reason*" 
                                item-text="name"
                                item-value="name"
                                data-cy="form-decline-reason"
                                clearable
                                return-object>
                            </v-autocomplete>
                        </v-flex>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled='isDeclineDisabled' v-if="isUnmatched()" color="green darken-1" text @click="toDecline" data-cy="form-decline-button">
                    Decline
                </v-btn>
                <v-btn color="green darken-1" text @click="close" data-cy="form-close-button">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        details: Object,
        listOfDeclineReason: Array,
    },
    computed: {
        populatedForm() {
            return {
                name: this.details.item.name,
                id: this.details.item.id
            }
        },
        isDeclineDisabled() {
            if(!(this.details.item.name)) {
               return this.buttonDisabled = true
           } else {
               return this.buttonDisabled = false
           }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.isShowDetails = value
            }
        },
        listOfDeclineReason: {
            handler(value) {
                this.reasonList = value ? value : [];
            },
        },
        deep: true,
    },
    data() {
        return {
            name: undefined,
            reasonList: this.listOfDeclineReason ?? [],
            isShowDetails: this.isShow ?? false,
            form: {}
        }
    },
    methods: {
        getFields() {
            const arrOfArrays = Object.entries(this.details.item);

            const sorted = arrOfArrays.sort((a, b) => {
            return a[0].localeCompare(b[0]);
            });

            return sorted;
        },
        convertCamelCase(string) {
            return string.replace(/([a-z])([A-Z])/g, '$1 $2');
        },

        //decline-transaction
        toDecline() {
            this.$emit("decline-transaction", this.populatedForm);
        },        

        close() {
            this.isShowDetails = false
            this.$emit('close-dialog', 'details')
        },
        isJSON(value) {
          return Object.prototype.toString.call(value) === '[object Array]' || Object.prototype.toString.call(value) === '[object Object]'
        },
        stringify(value) {
          if(Object.prototype.toString.call(value) === '[object Array]') {
            var items = []
            for(var index in value) {
              if(value[index].role) {
                items.push(value[index].role)
              }
            }
            if(items.length > 0) {
              return items.toString()
            }
            return JSON.stringify(value)
          } else if(Object.prototype.toString.call(value) === '[object Object]') {
            if(value.role) {
              return value.role
            }
            return JSON.stringify(value)
          } else {
            return value.toString()
          }
        },
        isUnmatched() {
            if(this.details.isUnmatched === true) {
                return true
            } else {
                return false
            }   
        }, 
    }
}
</script>
